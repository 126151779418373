const isObject = (value) => {
  return value !== null && typeof value === "object";
};
const isTrueOrFalse = (value) => {
  return value === "true" || value === true ? true : false;
};
const isNull = (value) => {
  return value === null || value === undefined ? true : false;
};
const isNullOrEmpty = (value) => {
  return isNull(value) || value === "" ? true : false;
};
export default { isObject, isTrueOrFalse, isNull, isNullOrEmpty };
