import moment from "moment";

const getDisplayDetails = (rowId, gridApi, columnApi) => {
  const node = gridApi.getRowNode(rowId);
  const columns = columnApi.getAllColumns();
  return columns.map((column) => {
    let value = gridApi.getValue(column.colId, node);
    // 指定したtypeでフォーマットする
    value = typeFormat(column.colDef.type, value);
    return { name: column.colDef.headerName, value };
  });
};

const getDisplayDetails2 = (rowId, columns, gridApi) => {
  const node = gridApi.getRowNode(rowId);
  return columns.map((column) => {
    let colId = column.colId ? column.colId : column.field;
    let type = column.type ? column.type : null;
    let value = gridApi.getValue(colId, node);
    // 指定したtypeでフォーマットする
    value = typeFormat(type, value);
    // class定義を取得する
    let classes = getClasses(column.cellClass, value);
    return { name: column.headerName, value, classes: classes };
  });
};

const getDisplayDetailsValue = (columns, values) => {
  return columns.map((column) => {
    let colId = column.colId ? column.colId : column.field;
    let type = column.type ? column.type : null;
    let value = values[colId];
    // 指定したtypeでフォーマットする
    value = typeFormat(type, value);
    // class定義を取得する
    let classes = getClasses(column.cellClass, value);
    return { name: column.headerName, value, classes: classes };
  });
};

const typeFormat = (type, value) => {
  if (value == null || value === "") return value;
  if (type === "dpDateColumn") {
    const date = moment(new Date(value));
    value = date.isValid() ? date.format("YYYY/MM/DD") : null;
  } else if (type === "dpFullDateColumn") {
    const date = moment(new Date(value));
    value = date.isValid() ? date.format("YYYY/MM/DD HH:mm:ss") : null;
  } else if (type === "dpNumericColumn") {
    value = value.toLocaleString();
  } else if (type === "dpPercentColumn") {
    value = Number(value).toFixed(1).toString() + "%";
  } else if (type === "dpCheckmarkColumn") {
    if (value === true) value = "✓";
    if (value === false) value = "";
  } else if (type === "dpMonthColumn") {
    const date = moment(new Date(value));
    value = date.isValid() ? date.format("MM") : null;
  } else if (Array.isArray(value)) {
    value = value?.join(", ");
  }
  return value;
};

const getClasses = (cellClass, value) => {
  let classes = [];
  if (cellClass != null && cellClass != undefined && typeof cellClass == "function") {
    classes = cellClass({ value: value });
  }
  return classes;
};

const getProductDisplayDetailValues = (rowId, gridApi, columnApi) => {
  const node = gridApi.getRowNode(rowId);
  const columns = columnApi.getAllColumns();
  let ret = {};
  columns.forEach((column) => {
    let value = gridApi.getValue(column.colId, node);
    // 指定したtypeでフォーマットする
    value = typeFormat(column.colDef.type, value);
    ret[column.colId] = value;
  });
  return ret;
};

const getProductDisplayDetailValues2 = (rowId, columns, gridApi) => {
  const node = gridApi.getRowNode(rowId);
  let ret = {};
  columns.forEach((column) => {
    let colId = column.colId ? column.colId : column.field;
    let type = column.type ? column.type : null;
    let value = gridApi.getValue(colId, node);
    // 指定したtypeでフォーマットする
    value = typeFormat(type, value);
    ret[colId] = value;
  });
  return ret;
};

const getColumnDef = (columnDefs, fieldName) => {
  let ret = columnDefs.find((columnDef) => {
    return columnDef.field === fieldName;
  });
  if (!ret) {
    ret = columnDefs.find((columnDef) => {
      return columnDef.colId === fieldName;
    });
  }
  return ret;
};

export {
  getDisplayDetails,
  getDisplayDetails2,
  getDisplayDetailsValue,
  getProductDisplayDetailValues,
  getProductDisplayDetailValues2,
  typeFormat,
  getColumnDef,
};
