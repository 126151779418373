<template>
  <v-date-picker
    no-title
    locale="ja"
    v-model="bindDate"
    type="date"
    :picker-date.sync="pickerDate"
    @input="onPickerChanged"
  >
  </v-date-picker>
</template>

<style>
.v-date-picker-table {
  height: auto !important;
}
</style>

<script>
// import Vue from "vue";
import moment from "moment";

export default {
  data() {
    return {
      value: null,
      bindDate: null,
      pickerDate: null,
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    initOfAbout(dateString) {
      const matches = /^(?<y>[0-9]{4}).(?<m>0[1-9]|1[0-2]).(?<d>[^0-9]+)$/.exec(dateString);
      if (matches != null && matches.length === 4) {
        this.clear();
      } else {
        this.initOfDate(dateString);
      }
    },
    initOfDate(dateString) {
      const yyyymmdd = moment(dateString, "YYYY-MM-DD");
      this.bindDate = yyyymmdd.format("YYYY-MM-DD");
      this.value = yyyymmdd.format("YYYY-MM-DD");
      this.pickerDate = yyyymmdd.format("YYYY-MM");
    },
    clear() {
      this.bindDate = null;
      this.value = null;
      this.pickerDate = null;
      this.cleanPickerDate();
    },
    cleanPickerDate(force) {
      if (!this.pickerDate || force) {
        this.pickerDate = moment().format("YYYY-MM");
      }
      console.log(this.pickerDate);
    },
    onPickerChanged(e) {
      const yyyymmdd = moment(e, "YYYY-MM-DD").format("YYYY-MM-DD");
      this.value = yyyymmdd;
      this.params.api.stopEditing();
    },
  },
  mounted() {
    this.value = this.params.value;
    console.log(this.value);
    if (this.value && this.value.length > 0) {
      this.initOfAbout(this.value);
    } else {
      this.clear();
    }
  },
};
</script>
