<template>
  <input type="date" v-model="value" style="width: 100%; outline: none; background-color: #ffffff" />
</template>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";

export default VueJS.extend({
  data() {
    return {
      value: null,
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
  },
  mounted() {
    this.value = this.params.value;
  },
});
</script>
