// Custom Column Types - https://www.ag-grid.com/javascript-grid/column-definitions/#custom-column-types
import { NumberValueFormatter, NumberDecimalValueFormatter, DateValueFormatter } from "./valueFormatters";
import { CheckmarkCellRenderer, CheckboxCellRenderer } from "./cellRenderers";
import ConvertUtils from "../../utils/ConvertUtils";

// /node_modules/ag-grid-community/dist/ag-grid-community.js
// var DefaultColumnTypes = {
//   numericColumn: {
//       headerClass: 'ag-right-aligned-header',
//       cellClass: 'ag-right-aligned-cell'
//   },
//   rightAligned: {
//       headerClass: 'ag-right-aligned-header',
//       cellClass: 'ag-right-aligned-cell'
//   }
// };

const checkStatuses = { true: "✔", false: "　" };

export const NumericColumn = {
  cellClass: "ag-right-aligned-cell",
  filter: "agNumberColumnFilter",
  valueFormatter: NumberValueFormatter(),
};

export const NumericDP2Column = {
  cellClass: "ag-right-aligned-cell",
  filter: "agNumberColumnFilter",
  valueFormatter: NumberDecimalValueFormatter(),
};

export const PercentColumn = {
  ...NumericColumn,
  valueFormatter: (params) => {
    if (params.value != null) {
      if (isNaN(Number(params.value))) {
        return params.value;
      } else {
        return Number(params.value).toFixed(1) + "%";
      }
    } else {
      return null;
    }
  },
};

export const DateColumn = {
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      let cellDate = null;
      cellValue = new Date(cellValue);
      if (cellValue) {
        cellDate = new Date(cellValue.getFullYear(), cellValue.getMonth(), cellValue.getDate());
      }
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    inRangeInclusive: true,
  },
  filter: "agDateColumnFilter",
  valueFormatter: DateValueFormatter("YYYY/MM/DD"),
};

export const FullDateColumn = {
  filter: false,
  valueFormatter: DateValueFormatter(),
};

export const CheckmarkColumn = {
  filter: "dpBooleanFilter",
  filterParams: { caption: { true: "✔", false: "　" } },
  cellRenderer: CheckmarkCellRenderer(),
};

export const EditableColumn = {
  editable: true,
  cellStyle: { backgroundColor: "#FFEDB3" },
};

export const EditableDateColumn = {
  ...EditableColumn,
  minWidth: 135,
  filter: "agDateColumnFilter",
  cellEditor: "dpDateCellEditor",
};

export const EditableCheckBoxColumn = {
  ...EditableColumn,
  minWidth: 135,
  cellRenderer: CheckboxCellRenderer(),
};

export const EditableCheckmarkColumn = {
  ...CheckmarkColumn,
  ...EditableColumn,
  cellEditor: "agSelectCellEditor",
  cellEditorPopup: true,
  cellEditorParams: {
    values: [true, false],
    cellEditorPopup: true,
  },
  refData: checkStatuses,
};

export const MonthColumn = {
  valueFormatter: DateValueFormatter("MM"),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDate, cellValue) => {
      let cellDate = null;
      filterLocalDate.setDate(1);
      // console.log("filterLocalDateAtMidnight::" + filterLocalDate);
      // console.log("cellValue::" + cellValue);
      cellValue = new Date(cellValue);

      if (cellValue) {
        cellDate = new Date(cellValue.getFullYear(), cellValue.getMonth());
      } else {
        return -1;
      }
      if (filterLocalDate.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDate) {
        return -1;
      }
      if (cellDate > filterLocalDate) {
        return 1;
      }
    },
  },
};

export const EditableCustomDateColumn = {
  ...EditableColumn,
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      let cellDate = null;
      cellValue = new Date(cellValue);
      if (cellValue) {
        cellDate = new Date(cellValue.getFullYear(), cellValue.getMonth(), cellValue.getDate());
      }
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    inRangeInclusive: true,
  },
  valueFormatter: DateValueFormatter("YYYY/MM/DD"),
};

export const IncludeFilter = {
  filterOptions: [
    {
      displayKey: "containsWithConv",
      displayName: "含む",
      test: function (filterValue, cellValue) {
        let c1 = ConvertUtils.zenkana2Hankana(ConvertUtils.zenkaku2Hankaku(filterValue));
        let c2 = ConvertUtils.zenkana2Hankana(ConvertUtils.zenkaku2Hankaku(cellValue));
        if (c1 && c2 && c2.indexOf(c1.toLowerCase()) !== -1) {
          return true;
        }
        return false;
      },
    },
    {
      displayKey: "hukumu",
      displayName: "含む 複数",
      test: function (filterValue, cellValue) {
        let condList = filterValue.split(" ");
        for (let condstr of condList) {
          let c1 = ConvertUtils.zenkana2Hankana(ConvertUtils.zenkaku2Hankaku(condstr));
          let c2 = ConvertUtils.zenkana2Hankana(ConvertUtils.zenkaku2Hankaku(cellValue));
          if (c1 && c2 && c2.indexOf(c1.toLowerCase()) !== -1) {
            return true;
          }
        }
        return false;
      },
    },
    "notContains",
    "equals",
    "startsWith",
    "endsWith",
  ],
};
